<template>
  <div class="main">
    <div class="head">
      <el-image
        class="head-icon"
        @click="sendExit"
        :src="require(`@/assets/image/exit3.png`)"
        fit="contain"
      ></el-image>
      <div class="head-title">发布新职位</div>
    </div>
    <div class="center">
      <el-image
        class="postInfo"
        :src="require(`@/assets/image/workbasicinf.png`)"
      ></el-image>
      <div class="hintText">
        职位发布成功后，招聘类型、职位名称、职位类型、工作城市，将无法修改
      </div>
      <div class="infoForm">
        <el-row class="infoLine"
          ><el-col :span="3">公司：</el-col
          ><el-col :span="21">{{ jobDetail.chain_name }}</el-col></el-row
        >
        <el-row class="infoLine">
          <el-col :span="3"> 招聘类型：</el-col>
          <el-col :span="21">
            <el-select
              v-model="jobDetail.job_duration"
              filterable
              placeholder="请选择"
              class="formValue"
            >
              <el-option label="全职" value="全职"></el-option>
              <el-option label="兼职" value="兼职"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="infoLine">
          <el-col :span="3">职位名称：</el-col>
          <el-col :span="21"
            ><el-input
              v-model="jobDetail.job_title"
              class="formValue"
            ></el-input
          ></el-col>
        </el-row>
        <el-row class="infoLine">
          <el-col :span="3"> 职位描述：</el-col>
          <el-col :span="15">
            <el-input
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 16 }"
              placeholder="请输入内容"
              v-model="jobDetail.job_describe"
            >
            </el-input>
          </el-col>
          <el-col :span="6" class="describeHint">
            <p>可选模块：岗位职责、任职要求、岗位经验、教育背景、岗位福利等</p>
            <p>示例</p>
            <p>岗位职责</p>
            <p>1、xxxx</p>
            <p>2、xxxx</p>
          </el-col>
          <!-- <div class="altword">
              可选模块:
              <div class="altbutton">
                <div class="altbutword">岗位职责</div>
              </div>
              <div class="altbutton">
                <div class="altbutword">任职要求</div>
              </div>
              <div class="altbutton">
                <div class="altbutword">岗位福利</div>
              </div>
              <div class="altbutton">
                <div class="altbutword">教育背景</div>
              </div>
              <div class="altbutton">
                <div class="altbutword">行业经验</div>
              </div>
              <div class="altbutton">
                <div class="altbutword">岗位经验</div>
              </div>
            </div> -->
        </el-row>
        <!-- <el-row class="infoLine">
          <el-col :span="3"> 职位类型：</el-col>
          <el-col :span="21"
            ><el-input
              v-model="jobDetail.job_duration"
              class="formValue"
            ></el-input
          ></el-col>
        </el-row> -->
      </div>
      <el-image
        class="jobRequirIcon"
        :src="require(`@/assets/image/workdemand.png`)"
        fit="contain"
      ></el-image>
      <div class="hintText">
        我们将通过以下条件，为您精确推荐合适的牛人，请尽是详细填写
      </div>
      <div class="jobRequirForm">
        <el-row class="jobRequirLine">
          <el-col :span="3"> 经验和学历： </el-col>
          <el-col :span="21">
            <el-input
              v-model="jobDetail.job_age"
              placeholder="请输入工作经验"
              class="workExperience"
            >
            </el-input>
            <span class="unit">(年)</span>
            <el-select
              v-model="jobDetail.job_education"
              class="education"
              filterable
              placeholder="请选择"
            >
              <el-option label="初中" value="初中"></el-option>
              <el-option label="高中" value="高中"></el-option>
              <el-option label="中职" value="中职"></el-option>
              <el-option label="大专" value="大专"></el-option>
              <el-option label="本科" value="本科"></el-option>
              <el-option label="研究生" value="研究生"></el-option>
              <el-option label="博士生" value="博士生"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="jobRequirLine">
          <el-col :span="3">薪资详情：</el-col>
          <el-col :span="21">
            <el-input
              v-model="jobDetail.job_salary"
              class="workExperience"
              placeholder="薪资范围如：6000-8000"
            ></el-input
            ><span class="unit">(元)</span
            ><el-input
              v-model="jobDetail.job_salary_constitute"
              class="workExperience"
              placeholder="薪资情况如：13薪"
            ></el-input>
          </el-col> </el-row
        ><el-row class="jobRequirLine">
          <el-col :span="3">年龄要求：</el-col>
          <el-col :span="21">
            <el-input
              v-model="jobDetail.age"
              class="workExperience"
              placeholder="年龄范围如：18-45"
            ></el-input
            ><span class="unit">(岁)</span>
          </el-col>
        </el-row>
        <el-row class="jobRequirLine">
          <el-col :span="3"> 职位关键词：</el-col>
          <el-col :span="15">
            <el-row>
              <el-col
                :span="6"
                class="tags"
                v-for="(item, index) in jobkeyword"
                :key="index"
              >
                <el-input
                  class="tagValue"
                  v-model="item.value"
                  clearable
                ></el-input
                ><el-button
                  size="mini"
                  circle
                  icon="el-icon-close"
                  class="deleteTag"
                  @click="delkey(index)"
                ></el-button> </el-col
            ></el-row>
            <el-button
              type="primary"
              circle
              icon="el-icon-plus"
              class="addTag"
              @click="addkey"
            ></el-button
          ></el-col>
        </el-row>
        <el-row class="jobRequirLine">
          <el-col :span="3"> 工作地区：</el-col>
          <el-col :span="21">
            <el-cascader
              v-model="cityData"
              :options="cityOptions"
              @change="handleCityChange"
              class="formValue"
            ></el-cascader>
          </el-col>
        </el-row>
        <el-row class="jobRequirLine">
          <el-col :span="3">详细地址：</el-col>
          <el-col :span="21">
            <el-input
              v-model="jobDetail.job_street"
              class="formValue"
              placeholder="请输入公司地区 + 详细地址"
            ></el-input
          ></el-col>
        </el-row>
        <el-row class="jobRequirLine">
          <el-col :span="3">截至日期：</el-col>
          <el-col :span="21">
            <el-date-picker
              v-model="jobDetail.expiration_time"
              type="date"
              placeholder="选择日期"
              class="formValue"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="main-bottom">
      <el-button class="delword" @click.native="deletejob" type="danger" round
        >删除</el-button
      >
      <el-button
        class="saveword"
        @click.native="saveandrelease"
        type="primary"
        round
        >保存并发布</el-button
      >
      <el-button class="exitword" @click.native="sendExit" type="warning" round
        >退出</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  getAiUserDetails,
  getJobDetails,
  insertJobData,
  deleteJob,
} from "@/request/api";
import Rogion from "@/assets/json/region.json";
export default {
  data() {
    return {
      jobDetail: {},
      jobkeyword: [],
      cityOptions: [], //省市的数据
      cityData: [], // 当前选中的城市
    };
  },
  methods: {
    // 编辑
    sendExit() {
      this.$router.push("/workFirm/FirmManagement");
    },
    async init() {
      if (this.shop_user_job_id) {
        try {
          const res = await getJobDetails(this.shop_user_job_id);
          let cityKey = "";
          let job_districtKey = "";
          for (let key in Rogion.province) {
            if (Rogion.province[key] === res.data.data.job_city) {
              cityKey = key;
              break;
            }
          }

          for (let key in Rogion[cityKey]) {
            if (Rogion[cityKey][key] === res.data.data.job_district) {
              job_districtKey = key;
              break;
            }
          }

          this.cityData = [cityKey, job_districtKey];
          console.log(this.cityData);
          this.jobDetail = res.data.data;
          //this.getCourse(this.stageGradeList[0].chat_stage_grade_id)
          const list = this.jobDetail.job_keyword.split(",");
          this.jobkeyword = list.map((item) => {
            return { value: item };
          });
        } catch (error) {
          this.$message.error("请求发生错误：" + error.message);
        }
      } else {
        try {
          const res = await getAiUserDetails();
          const details = res.data.data;
          this.jobDetail = {
            ...this.jobDetail,
            chain_name: details.getAiChain[0].chain_name,
          };
        } catch (err) {
          this.$message.error("获取数据出错了", err);
        }
      }
    },

    async saveandrelease() {
      const job_city = Rogion.province[this.cityData[0]];
      const job_district = Rogion[this.cityData[0]][this.cityData[1]];
      const res = await insertJobData({
        ...this.jobDetail,
        job_city,
        job_district,
        job_keyword: this.jobkeyword
          .map((item) => item.value)
          .filter(Boolean)
          .join(","),
      });
      if (res.data.code === "1000") {
        this.$message.success("发布成功");
        this.sendExit();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    deletejob() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let postData = {
            shop_user_job_id: this.shop_user_job_id,
          };
          const res = await deleteJob(postData);
          if (res.data.code === "1000") {
            this.$message.success("删除成功");
            this.sendExit();
            return;
          } else {
            this.$message.error(res.data.msg);
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addkey() {
      this.jobkeyword.push({ value: this.jobkeyword.length + 1 });
      // this.addkeyword = true;
    },
    delkey(index) {
      this.jobkeyword.splice(index, 1);
    },

    handleCityChange(value) {
      console.log(value, "value");
    },
  },
  created() {
    const keys = Object.keys(Rogion);
    //获取第一层级省级
    const options = keys
      .map((key) => {
        if (Rogion.province[key]) {
          return { value: key, label: Rogion.province[key] };
        }
      })
      .filter(Boolean);
    //转换为符合规定的格式
    const citys = options.map((item) => {
      const children = Object.keys(Rogion[item.value]);
      item.children = children.map((key) => {
        return { value: key, label: Rogion[item.value][key] };
      });
      return item;
    });
    console.log(citys, "citys");
    this.cityOptions = citys;
    this.shop_user_job_id = this.$route.query.shop_user_job_id;
  },

  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
// .cascader {
//   height: 300px;
//   .el-cascader-menu__list {
//     height: 300px;
//   }
// }
.main {
  background: #fff;
  padding: 20px;
  .head {
    display: flex;
    margin-bottom: 20px;
    .head-icon {
      width: 34px;
      height: 34px;
      cursor: pointer;
    }
    .head-title {
      font-size: 20px;
      line-height: 34px;
      margin-left: 15px;
    }
  }
  .postInfo {
    width: 224px;
    height: 39px;
  }
  .hintText {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .infoForm {
    margin-bottom: 30px;
  }
  .infoLine,
  .jobRequirLine {
    margin-bottom: 10px;
  }
  .formValue {
    width: 390px;
  }
  .describeHint {
    > p {
      font-size: 14px;
      line-height: 16px;
      color: #a8a7a7;
      margin: 5px;
    }
  }
  .jobRequirIcon {
    width: 172px;
    height: 39px;
  }
  .workExperience,
  .education {
    width: 190px;
  }
  .workExperience {
    margin-right: 10px;
  }
  .jobRequirForm {
    .deleteTag {
      width: 28px;
      height: 28px;
      font-size: 14px;
    }
    .addTag {
      width: 38px;
      height: 38px;
      font-size: 14px;
      // line-height: 38px;
    }
    .tags {
      display: flex;
      margin-bottom: 10px;
    }
    .tagValue {
      margin-right: 10px;
    }
    .deleteTag {
      margin-top: 5px;
      margin-right: 5px;
    }
  }
  .main-bottom {
    display: flex;
    justify-content: end;
  }
  .unit {
    margin-right: 10px;
    color: #a8a7a7;
  }
}
</style>
